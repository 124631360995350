$smlg-device: 'only screen and (max-width: 1199px)';

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)';
/* container 300px*/

@media #{$large-mobile} {
  .container {
    width: 100%;
  }
}

@media #{$small-mobile} {
  .container {
    width: 100%;
  }
}
/**/
/*----------------------------------------------------*/
/*font Variables*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&family=Keania+One&family=Zilla+Slab+Highlight:wght@400;700&display=swap');


/*---------------------------------------------------- */
/*----------------------------------------------------*/
.list_style {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body {
  background: #000;
  line-height: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #f5f5f5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

.mt_40 {
  margin-top: 40px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_80 {
  margin-top: 80px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_80 {
  margin-bottom: 80px;
}

.t_color {
  color: #f5f5f5;
  font-size: 20px;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
    overflow-x: hidden;
  }
}

.row_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.bottom_line {
  width: 80px;
  height: 2px;
  margin: 25px auto 0px;
  display: inline-block;
  background: #bcbcbc;
}

.title_h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.title_p {
  color: #f5f5f5;
  font-size:18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 0px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

/*============ theme_btn css =========*/
.theme_btn {
  font-size: 18px;
  font-weight: 600;
  color: #f5f5f5;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #f5f5f5;
  padding: 10px 36px;
  border-radius: 5px;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 1;
  transition: all 0.5s linear;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
  display: inline-block;
}

.theme_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 1px;
  background: #f6e4b7; /*button*/
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition: all 0.5s ease;
}

.theme_btn:hover {
  color: #444444;
}

.theme_btn:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.theme_btn2 {
  font-size: 18px;
  font-weight: 600;
  color: #f5f5f5;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #f5f5f5;
  padding: 10px 36px;
  border-radius: 5px;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 1;
  transition: all 0.5s linear;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
  display: inline-block;
}

.theme_btn2:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 1px;
  background: #f6e4b7; /*button*/
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition: all 0.5s ease;
}

.theme_btn2:hover {
  color: #444444;
}

.theme_btn2:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

/*========= header_area css ===========*/
.hamburger_mobile a {
  color: #4e4a46;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #c94e50;
  }
  &.active {
    color: #c94e50;
  }
}

.navbar {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  @media #{$lg-layout} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media #{$sm-layout} {
    padding-left: 30px;
    padding-right: 30px;
  }
}


.navbar .logo_h .lottie {
  justify-self: start;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.5);
}


.navbar .navbar-nav .nav-item {
  margin-left: 2em;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto;
}

.navbar .navbar-nav .nav-item .nav-link {
  font: 600 18px 'Raleway', sans-serif;
  color: #f5f5f5;
  position: relative;
  padding: 0;
  cursor: pointer;
  transition: all 0.5s ease;
}

.navbar .navbar-nav .nav-item .nav-link:before {
  content: "";
  background: #ffd300;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.navbar .navbar-nav .nav-item .nav-link.active:before, .navbar .navbar-nav .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
}

.navbar .navbar-nav.nav-right .nav-item {
  margin-left: 0;
}

.navbar_fixed .navbar{
  background: #fffffa;
  -webkit-box-shadow: 0px 10px 30px -8px rgba(0,0,0,0.69);
  -moz-box-shadow: 0px 10px 30px -8px rgba(0,0,0,0.69);
  box-shadow: 0px 10px 30px -8px rgba(0,0,0,0.69);
  top: 0;
}

.navbar_fixed .logo_h .lottie {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  box-shadow: 0px 30px 60px 0px rgba(0, 11, 40, 0.5);
}

.navbar_fixed .navbar .navbar-nav .nav-item .nav-link {
  color: #444444;
}

.navbar_fixed .navbar-nav .nav-item .nav-link:before {
  background: #daa520;
}

.navbar-toggler {
  border: 0;
  padding: 0;
  border-radius: 0;
  height: 5em;
  cursor: pointer;
}

.navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  background-image: -moz-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -webkit-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -ms-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  margin: auto;
  margin-bottom: 5px;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.navbar-toggler[aria-expanded="true"] span:first-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 6px;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
  bottom: 8px;
  position: relative;
}

.navbar-toggler:focus {
  outline: none;
}

.dark_menu .navbar-nav .nav-item .nav-link{
  color: #f6e4b7;
}
.dark_menu .navbar-nav .nav-item .nav-link.active:before, .dark_menu .navbar .navbar-nav .nav-item .nav-link:hover:before{
  background: #f6e4b7;
}
.dark_menu .login{
  background: #f6e4b7;
  color: #fff;
}
.dark_menu .logo_h img{
  display: none;
}
.dark_menu .logo_h img + img{
  display: block;
}
/*========== banner_area css =========*/
.banner_area,.banner_area_two {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.banner_area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.banner_content {
  color: #fff;
  position: relative;

}

.banner_content h5 {
  font-size: 20px;
  margin: 0;
  font-weight: 300;
  margin-bottom: 32px;
  letter-spacing: 1px;
}

.banner_content h1 {
  margin: 10px 0px;
  font-size: 65px;
  font-weight: 700;
  font-family: 'Zilla Slab Highlight', cursive;
  @media #{$large-mobile} {

    font-size: 3rem;
  }


}

.banner_content h4 {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 1.5em;
  padding: 10px 0px 30px;
}
@media #{$large-mobile} {
  .banner_content h4 {
    font-size: 17px;
  }
}
.banner_content h4 span{
  font-weight: 700;
}
.banner_content h4 a{
  font-weight: 400;
}

.banner_content .social_icon li {
  display: inline-block;
  margin-top: 0;
  margin-right: 2em;
  margin-bottom: 0;
}

.banner_content .social_icon li a {
  font-size: 20px;
  width: 42px;
  height: 42px;
  line-height: 45px;
  border-radius: 40%;
  background: rgba(172, 150, 107, 0.5);
  display: inline-block;
  color: #f5f5f5;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
}
@media #{$large-mobile} {
  .banner_content .social_icon li a {
    font-size: 17px;
  }
}
.banner_content .social_icon li a:hover {
  background: #f5f5f5;
  color: #AC966B;
}

.banner_area_two .banner_content{
  color: #f6e4b7;
  position: relative;
  z-index: 3;
}
/*========== banner_area css =========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== about_area css ===========*/
.about_area {
  overflow: hidden;
  padding-top: 80px;
}

.about_content {
  padding-right: 88px;
  padding-bottom: 50px;
}

.about_content h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.about_content h6 {
  color: #F7F7F7;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 16px;
}

.about_content p {
  color: #F7F7F7;
  padding-bottom: 30px;
}
.about_content .theme_btn.active{
  color: #444444;
}
.about_content .theme_btn.active:before{
  transform: scale(1);
}
.about_content .theme_btn + .theme_btn {
  margin-left: 15px;
}
.about_content .theme_btn.active:hover{
  color: #f6e4b7;
}
.about_content .theme_btn.active:hover:before{
  transform: scaleX(0);
}
.about_img img {
  margin-left: -50px;
}

.work_area {
  padding: 110px 0px 90px;

}

.work_item {
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px 25px 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  cursor: pointer;
}
.sponsors{ transition: transform .5s ease-in-out; }
.sponsors:hover{transform: scale(1.13);}

.work_item i {
  border: 1px solid #8a8a8a;
  border-radius: 50%;
  color: #8a8a8a;
  font-size: 32px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  width: 80px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  display: inline-block;
}

.work_item h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 5px;
  transition: color 0.3s linear;
}

.work_item:hover {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== portfolio_area css ============*/
.portfolio_area {
  padding: 110px 0px 90px;
}

.portfolio_menu {
  margin-bottom: 50px;
}

.grid-item {
  margin-bottom: 30px;
}

.portfolio {
  position: relative;
}

.portfolio img {
  max-width: 100%;
}


/*================== project_count_area css ================*/
.project_count_area {
  padding-top: 50px;
  padding-bottom: 50px;

}

.project_count_area .counter_item i {
  font-size: 35px;
  margin-top: 25px;
}


.project_count_area .counter_item .t_color {
  font: 400 20px/25px "Montserrat", sans-serif;
  letter-spacing: 1px;
}

.project_count_area .counter_item p {
  margin-bottom: 0px;
  font-size: 16px;
}

.p_count_two {
  background: #fff;
  padding: 80px 0px;
}

.p_count_two .counter_item i {
  color: #ff214f;
}

.bg_two {
  background: #fafafa;
}

/*=============== testimonial_area css  ==============*/
.testimonial_area {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 50px;
  @media #{$large-mobile}{
    padding-left:15%;
    padding-right:15%;
  }

}

.testimonial_area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.testimonial_area .testimonial_slider {
  text-align: center;
  padding: 0px 100px;
}

.testimonial_area .testimonial_slider .item {
  color: #fff;
}

.testimonial_area .testimonial_slider .item .author_img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ededed;
  background: #fff;
  @media #{$large-mobile}{
    width:125px;
    height:125px;
  }
}

.testimonial_area .testimonial_slider .item .author_img img {
  max-width: 100%;
  width: auto;
}

.testimonial_area .testimonial_slider .item h6 {
  color: #f5f5f5;
  font-size: 24px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  margin: 15px 0 0px;
}

.testimonial_area .testimonial_slider .item span {
  font: 400 16px "Montserrat", sans-serif;
  opacity: 0.6;
}

.testimonial_area .testimonial_slider .item p {
  margin-bottom: 0px;
  margin-top: 15px;
}
.testimonial_area .testimonial_slider .slick-dots {
  left: 0;
  position: relative;
}
.testimonial_area .testimonial_slider .slick-dots li{
  margin: 0px;
}
.testimonial_area .testimonial_slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  opacity: 0.50;
  margin: 0px 0px 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.testimonial_area .testimonial_slider .slick-dots li button:before{
  display: none;
}

.testimonial_area .testimonial_slider .slick-dots .slick-active button{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}


/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ contact-area css ===============*/
.contact-area {
  padding-top: 120px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 30px;
}

.contact-area h4 {
  font: 700 35px/40px "Montserrat", sans-serif;
  color: #f6e4b7;
  text-transform: capitalize;
  padding-bottom: 24px;
}

.contact-area .input_form form .form-control {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: #f5f5f5;
  letter-spacing: 1.5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(245, 245, 245, 0.51);
  border-radius: 5px;
  padding-left: 20px;
  min-height: 44px;
  margin-bottom: 30px;
}

.contact-area .input_form form .form-control.placeholder {
  color: rgba(68, 68, 68, 0.7);
}

.contact-area .input_form form .form-control:-moz-placeholder {
  color: rgba(68, 68, 68, 0.7);}

.contact-area .input_form form .form-control::-moz-placeholder {
  color: rgba(68, 68, 68, 0.7);}

.contact-area .input_form form .form-control::-webkit-input-placeholder {
  color: rgba(68, 68, 68, 0.7);}

.contact-area .input_form form .form-control.error {
  margin-bottom: 0;
}

.contact-area .input_form form .form-control.error + .error {
  font: 400 10px/30px "Montserrat", sans-serif;
  color: #444444;
  background: none;
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.contact-area .input_form form textarea.form-control {
  resize: none;
  height: 142px;
  padding-top: 12px;
}

.contact-area .input_form form .send_btn {
  font-size: 16px;
  border-radius: 4px;
  color: #f6e4b7;
  padding: 14px 38px 14px 42px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: transparent;
  box-shadow: none;
}
.contact-area .input_form form .send_btn:hover{
  color: #444444;
}
.contact-area .input_form #success, .contact-area .input_form #error {
  position: absolute;
  top: -15px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.contact-area .input_form #success p, .contact-area .input_form #error p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}

.contact-area .input_form #success {
  color: lawngreen;
}

.contact-area .input_form #error {
  color: orangered;
}

.contact-area .contact_info {
  padding-left: 50px;
}

.contact-area .contact_info ul {
  display: block;
  padding-right: 60px;
}

.contact-area .contact_info ul .item {
  cursor: pointer;
  display: block;
}

.contact-area .contact_info ul .item .media i {
  color: #f6e4b7;
  line-height: 28px;
  text-align: center;
  font-size: 20px;
  padding-right: 10px;
}

.contact-area .contact_info ul .item .media .media-body {
  vertical-align: middle;
}

.contact-area .contact_info ul .item .media .media-body a {
  color: #f5f5f5;
  font: 400 16px "Montserrat", sans-serif;
}

.contact-area .contact_info ul .item + .item {
  padding-top: 15px;
}

/*========= Start footer area css ========*/
.footer-area {
  position: relative;
  padding: 60px 0 0;
}

.footer-content {
  text-align: center;
}

.footer-content .logo {
  display: inline-block;
  margin-bottom: 30px;
}

.footer-content ul li {
  display: inline-block;
  padding: 0 5px;
}

.footer-content ul li a {
  width: 35px;
  height: 35px;
  border: 1px solid #f5f5f5;
  color: #f5f5f5;
  font-size: 12px;
  line-height: 35px;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: center;
}

.footer-content ul li a:hover {
  background: #f6e4b7;
  color: #444444;
}

.footer-content h6 {
  font: 300 15px "Montserrat", sans-serif;
  color: #f5f5f5;
  padding-top: 15px;
  letter-spacing: 1.5px;
}

.footer-content h6 a {
  color: #f6e4b7;
}

.footer-content p {
  font: 300 13px "Montserrat", sans-serif;
  color: #f5f5f5;
  letter-spacing: 1.7px;
  margin-bottom: 0;
}

/*========= End footer area css ========*/
/*---------------------------------------------------- */

.bg_overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00010c;
  opacity: 0.50;
}

.bg_overlay {
  position: relative;
}


.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/**/
.slider-fixed--height {
  min-height: 100vh;
  width: 100%;

  @media #{$laptop-device} {
    min-height: 100vh;
  }

  @media #{$sm-layout} {
    min-height: 100vh;
    padding: 60px 0;
  }
  @media #{$md-layout} {
    min-height: 100vh;
    padding: 80px 0;
    padding-bottom: 60px;
  }
}

.slide {
  .inner {
    padding-top: 100px;
    padding-bottom: 100px;
    @media #{$sm-layout} {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media #{$large-mobile} {
      padding-top: 30px;
      padding-bottom: 30px;
    }


  }

  &.slide-style-1 {
    .inner {
      padding-top: 100px;
      @media #{$sm-layout} {
        padding-top: 70px;
      }
      @media #{$large-mobile} {
        padding-top: 30px;
      }
      @media #{$md-layout} {
        padding-top: 50px;
      }

      h1 {
        &.title {
          @media #{$md-layout} {
            font-size: 70px;
            line-height: 82px;
            margin-bottom: 26px;
          }
        }
      }
    }
  }}
/**/
/*=============================
	Overlay styles
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
  z-index: 1;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
  position: relative;
  z-index: 1;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

[data-overlay]:before {
  background-color: #0db3c7;
}

[data-black-overlay]:before {
  background-color: #00010c;
}

[data-white-overlay]:before {
  background-color: #f5f5f5;
}

@for $i from 1 through 10 {
  [data-overlay="#{$i}"]:before,
  [data-black-overlay="#{$i}"]:before,
  [data-white-overlay="#{$i}"]:before {
    opacity: #{$i * 0.10};
  }
}